<template>
<!-- <div class="test-backgound"> -->
    <!-- <MainMenu @clickMenuFromChild="selectedContent" /> -->
<!-- </div> -->
    <Docent :content="content" />
    <MainModal :index="4" @closeFromAlertChild="closeModal" @zoomOutCamera="zoomOutCamera" />

</template>

<script>
// import Docent from "@/components/docent/Docent.vue";
import MainModal from "@/components/modal/MainModal.vue";
// import MainMenu from "@/components/menu/MainMenu.vue";

export default {
    components: {
        MainModal,
        // Docent,
        // MainMenu
    },
    setup() {
        return {
            content: {
                ko: "안녕하세요.\n 테스트입니다."
            }
        }
    },
    methods: {
        selectedContent(index) {
            console.log(index)
        }
    }
}
</script>

<style scoped>
.test-backgound {
    background-color: black;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}
</style>